/**
 *​ Funzione per clonare senze nessuna referenza un oggetto (o un array di oggetti) complesso.
 *
 * JSON.stringify/parse​ only work with Number and String and Object literal without function or Symbol properties:
 * https://medium.com/dailyjs/3-ways-to-clone-objects-in-javascript-22deed66f39d
 *
 * @export
 * @param obj
 * @returns
 */
export function deepCopy(obj) {
  return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}
