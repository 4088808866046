import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DewPopAlertComponent } from './components/dew-popalert.component';
import { AuthGuardService } from './services/auth-guard.service';
import { DewCoreService } from './services/dew-core.service';
import { DewHttpInterceptor } from './services/dew-http-interceptor';
import { DewHttpService } from './services/dew-http.service';
import { DewPopAlertService } from './services/dew-popalert.service';
import { DewStateService } from './services/dew-state.service';
import { DewStorageService } from './services/dew-storage.service';
import { PermissionGuardService } from './services/permission-guard.service';
import { PwaService } from './services/pwa-service.service';

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [DewPopAlertComponent],
  exports: [DewPopAlertComponent],
  providers: [
    DewCoreService,
    DewHttpService,
    DewPopAlertService,
    DewStorageService,
    DewStateService,
    AuthGuardService,
    PermissionGuardService,
    PwaService,
    { provide: HTTP_INTERCEPTORS, useClass: DewHttpInterceptor, multi: true },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    translate: TranslateService
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }

    translate.addLangs(['it', 'en']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/it|en/) ? browserLang : 'it');
  }
}
