export function getStatiOrdine(): SelectItem<StatoOrdine>[] {
  return [
    { id: StatoOrdine.PresoInCarico, descrizione: 'Preso in carico' },
    { id: StatoOrdine.Processato, descrizione: 'Processato' },
    { id: StatoOrdine.InLavorazione, descrizione: 'In lavorazione' },
    { id: StatoOrdine.ProntoParziale, descrizione: 'Pronto parziale' },
    { id: StatoOrdine.Pronto, descrizione: 'Pronto' },
    { id: StatoOrdine.Consegnato, descrizione: 'Consegnato' },
  ];
}
