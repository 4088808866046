import { HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';

/**
 * Esegue il download del file
 *
 * @export
 * @param {HttpResponse<Blob>} response
 */
export function downloadFile(response: HttpResponse<Blob>, fileName?: string): void {
  if (!fileName) {
    fileName = getFileNameFromResponseContentDisposition(response);
  }

  const responseBlob =
    response instanceof HttpResponse ? response.body : (response as any).error instanceof Blob ? (response as any).error : undefined;

  FileSaver.saveAs(responseBlob, fileName);
}

/**
 * Esegue il download di un file base64
 *
 * @export
 * @param {string} data
 */
export function downloadFileFromBase64(data: string): void {
  const byteArray = new Uint8Array(
    atob(data)
      .split('')
      .map((char) => char.charCodeAt(0))
  );
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(blob);

  window.open(fileURL);
}

/**
 * Apre il file in una nuova tab
 *
 * @export
 * @param {HttpResponse<Blob>} response
 */
export function openFile(response: HttpResponse<Blob>): void {
  const file = new Blob([response.body], { type: response.body.type });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

/**
 * Derives file name from the http response
 * by looking inside content-disposition
 * @param {HttpResponse<Blob>} res
 * @returns
 */
export const getFileNameFromResponseContentDisposition = (res: HttpResponse<Blob>) => {
  const contentDisposition = res.headers.get('content-disposition') || '';

  let filename = 'untitled';
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

  // filename      # match filename, followed by
  // [^;=\n]*      # anything but a ;, a = or a newline
  // =
  // (             # first capturing group
  //     (['"])    # either single or double quote, put it in capturing group 2
  //     .*?       # anything up until the first...
  //     \2        # matching quote (single if we found single, double if we find double)
  // |             # OR
  //     [^;\n]*   # anything but a ; or a newline
  // )

  const matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, '');
  }
  return filename.trim();
};
