import { Injectable } from '@angular/core';
import { AppConfig } from '@config/app.config';

export const StorageModeType = {
  session: 'session',
  local: 'local',
};

@Injectable()
export class DewStorageService {
  constructor() {}

  get(key: string, storageMode = AppConfig.defaultStorageMode): string {
    if (storageMode === StorageModeType.local) {
      return this.localGet(key);
    }
    return this.sessionGet(key);
  }

  set(key: string, data: string, storageMode = AppConfig.defaultStorageMode): void {
    if (storageMode === StorageModeType.local) {
      this.localSet(key, data);
      return;
    }

    this.sessionSet(key, data);
  }

  clearAll(storageMode = AppConfig.defaultStorageMode): void {
    if (storageMode === StorageModeType.local) {
      this.localClearAll();
      return;
    }
    this.sessionClearAll();
  }

  /**
   * SessionStorage
   * * I dati salvati con localStorage non hanno __expiration time__.
   * * I dati persistono anche tra diverse sessioni browser.
   */
  private localGet(key: string): string {
    return localStorage.getItem(key);
  }

  private localSet(key: string, data: string): void {
    localStorage.setItem(key, data);
  }

  private localClearAll(): void {
    localStorage.clear();
  }

  /**
   * SessionStorage
   * * I dati in SessionStorage vengono ripuliti ogniqualvolta la sessione della pagine termina.
   * * La sessione della pagina dura fino a quando il browser è aperto e sopravvive alla ricarica della pagina e al ripristino.
   * * L'apertuta di una nuova tab o una nuova finestra implica l'apertura di una nuova sessione.
   */
  private sessionGet(key: string): string {
    return sessionStorage.getItem(key);
  }

  private sessionSet(key: string, data: string): void {
    sessionStorage.setItem(key, data);
  }

  private sessionClearAll(): void {
    sessionStorage.clear();
  }
}
