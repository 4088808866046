import { Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DewEventService implements OnDestroy {
  private _evts: {
    [_key: string]: Subject<unknown>;
  } = {};

  ngOnDestroy() {
    for (const _key in this._evts) {
      // Controllo sulla proprietà per TsLint check: for (... in ...) statements must be filtered with an if statement
      if (this._evts.hasOwnProperty(_key)) {
        this._evts[_key].complete();
        (this._evts[_key] as unknown) = undefined;
      }
    }
  }

  dispatch<T>(eventName: InjectionToken<T>, eventPayload: T): void {
    const _key = eventName.toString();

    if (_key in this._evts) {
      this._evts[_key].next(eventPayload);
    }
  }

  handleEvent$<T>(eventName: InjectionToken<T>, destroy$: Observable<void>): Observable<T> {
    const _key = eventName.toString();
    if (!(_key in this._evts)) {
      this._evts[_key] = new Subject<T>();
    }

    return (this._evts[_key].asObservable() as Observable<T>).pipe(
      filter((t) => t !== undefined),
      takeUntil(destroy$)
    );
  }
}
