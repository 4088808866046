import * as moment from 'moment';

/**
 * Calcola la differenza di date in ore
 *
 * @export
 * @param {string} start
 * @param {string} end
 * @returns {number}
 */
export function dateDifference(start: string, end?: string | moment.Moment): number {
  const duration = moment.duration(moment(end).diff(start));

  return Math.floor(duration.asMinutes()) / 60;
}

/**
 * Converte la data per i servizi rest
 *
 * @export
 * @param {(string | Date)} value
 * @returns {*}
 */
export function dateToRest(value: string | Date): any {
  try {
    const momentDate = moment(value);
    const result = momentDate.isValid() ? moment(value).format('YYYY-MM-DD HH:mm:00') : value;
    return result;
  } catch (error) {
    return value;
  }
}
