import { Injectable } from '@angular/core';
import { getLingue } from '@shared/functions';
import { Observable, Subject } from 'rxjs';
import { DewStateService } from './dew-state.service';
import { DewStorageService } from './dew-storage.service';

/**
 * Servizi globali per l'applicazione.
 * Sono contenuti i servizi comuni tra i diversi moduli.
 */
@Injectable()
export class DewCoreService {
  private _isRunning$: Subject<boolean>;

  constructor(private stateService: DewStateService, private storageService: DewStorageService) {
    this._isRunning$ = new Subject<boolean>();
  }

  // Gestione per il loading delle chiamate HTTP
  get isHttpRunning$(): Observable<boolean> {
    return this._isRunning$.asObservable();
  }

  startHttpRunning() {
    this._isRunning$.next(true);
  }

  stopHttpRunning() {
    this._isRunning$.next(false);
  }

  setProfilo(profilo: LoginDto): void {
    profilo.Lingua = profilo.Lingua.toLowerCase();

    if (!getLingue().find((i) => i.id === profilo.Lingua)) {
      profilo.Lingua = 'it';
    }

    this.storageService.set(ConfigSession.token, profilo.Token);
    this.stateService.dispatch('profilo', profilo);
  }
}
