import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { DewPermissionService } from './dew-permission.service';
import { DewPopAlertService } from './dew-popalert.service';

/**
 * Impedisce l'accesso alle route se non si hanno i permessi
 */
@Injectable()
export class PermissionGuardService implements CanActivate {
  constructor(private router: Router, private permissionService: DewPermissionService, private popAlertService: DewPopAlertService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const params = route.data as { permission: LivelloPermessi };
    if (!params || !params.permission) {
      this.returnError();
      return false;
    }

    if (this.permissionService.isGranted(params.permission)) {
      return true;
    }

    this.returnError();
    return false;
  }

  private returnError() {
    this.popAlertService.showErrorMessage('MSG.PERMISSION-ERROR');
    this.router.navigate(['./']);
  }
}
