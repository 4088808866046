import * as moment from 'moment';
import { dateToRest } from './dew-date-functions';

export interface DewRange {
  id: number;
  descrizione: string;
  dataInizio: string;
  dataFine: string;
}

export const RangeTypes = {
  MeseInCorso: 0,
  MesePrecedente: 1,
  MeseSuccessivo: 2,
  TrimestreInCorso: 3,
  TrimestrePrecedente: 4,
  TrimestreSuccessivo: 5,
  AnnoInCorso: 6,
  AnnoPrecedente: 7,
  Oggi: 8,
  Ieri: 11,
  SettimanaInCorso: 9,
  SettimanaProssima: 10,
};

const Ranges: DewRange[] = [
  {
    id: RangeTypes.Oggi,
    descrizione: 'Oggi',
    dataInizio: dateToRest(moment().startOf('day').toISOString()),
    dataFine: dateToRest(moment().endOf('day').toISOString()),
  },
  {
    id: RangeTypes.Ieri,
    descrizione: 'Ieri',
    dataInizio: dateToRest(moment().subtract(1, 'day').startOf('day').toISOString()),
    dataFine: dateToRest(moment().subtract(1, 'day').endOf('day').toISOString()),
  },
  {
    id: RangeTypes.SettimanaInCorso,
    descrizione: 'Settimana corrente',
    dataInizio: dateToRest(moment().startOf('week').toISOString()),
    dataFine: dateToRest(moment().endOf('week').toISOString()),
  },
  {
    id: RangeTypes.SettimanaProssima,
    descrizione: 'Settimana prossima',
    dataInizio: dateToRest(moment().add(1, 'week').startOf('week').toISOString()),
    dataFine: dateToRest(moment().add(1, 'week').endOf('week').toISOString()),
  },
  {
    id: RangeTypes.MeseInCorso,
    descrizione: 'Mese in corso',
    // da inizio mese corrente
    dataInizio: dateToRest(moment().startOf('month').toISOString()),
    // fino ad oggi
    dataFine: dateToRest(moment().endOf('month').toISOString()),
  },
  {
    id: RangeTypes.MesePrecedente,
    descrizione: 'Mese precedente',
    // da inizio mese precedente
    dataInizio: dateToRest(moment().subtract(1, 'months').startOf('month').toISOString()),
    // a fine mese precedente
    dataFine: dateToRest(moment().subtract(1, 'months').endOf('month').toISOString()),
  },
  {
    id: RangeTypes.MeseSuccessivo,
    descrizione: 'Mese successivo',
    // da inizio mese successivo
    dataInizio: dateToRest(moment().endOf('month').add(1, 'days').startOf('month').toDate().toISOString()),
    // a fine mese successivo
    dataFine: dateToRest(moment().endOf('month').add(1, 'days').endOf('month').toDate().toISOString()),
  },
  {
    id: RangeTypes.TrimestreInCorso,
    descrizione: 'Trimestre in corso',
    // da inizio trimestre corrente
    dataInizio: dateToRest(moment().startOf('quarter').toISOString()),
    // fino ad oggi
    dataFine: dateToRest(moment().endOf('quarter').toISOString()),
  },
  {
    id: RangeTypes.TrimestrePrecedente,
    descrizione: 'Trimestre precedente',
    // da inizio trimestre precedente
    dataInizio: dateToRest(moment().subtract(1, 'quarters').startOf('quarter').toISOString()),
    // a fine trimestre precedente
    dataFine: dateToRest(moment().subtract(1, 'quarters').endOf('quarter').toISOString()),
  },
  {
    id: RangeTypes.TrimestreSuccessivo,
    descrizione: 'Trimestre successivo',
    // da inizio trimestre successivo
    dataInizio: dateToRest(moment().quarter(moment().quarter()).add(1, 'quarters').startOf('quarter').toDate().toISOString()),
    // a fine trimestre successivo
    dataFine: dateToRest(moment().quarter(moment().quarter()).add(1, 'quarters').endOf('quarter').toDate().toISOString()),
  },
  {
    id: RangeTypes.AnnoInCorso,
    descrizione: 'Anno in corso',
    // da inizio anno
    dataInizio: dateToRest(moment().startOf('year').toISOString()),
    // a fino anno
    dataFine: dateToRest(moment().endOf('year').toISOString()),
  },
  {
    id: RangeTypes.AnnoPrecedente,
    descrizione: 'Anno precedente',
    // da inizio anno precedente
    dataInizio: dateToRest(moment().subtract(1, 'years').startOf('year').toISOString()),
    // a fine anno precedente
    dataFine: dateToRest(moment().subtract(1, 'years').endOf('year').toISOString()),
  },
];

export function getAllRange(): DewRange[] {
  return Ranges;
}

export function getRangeMesi(): DewRange[] {
  return selezionaRange([RangeTypes.MeseInCorso, RangeTypes.MesePrecedente, RangeTypes.MeseSuccessivo]);
}

export function getRangeTrimestri(): DewRange[] {
  return selezionaRange([RangeTypes.TrimestreInCorso, RangeTypes.TrimestrePrecedente, RangeTypes.TrimestreSuccessivo]);
}

export function getRangeAnni(): DewRange[] {
  return selezionaRange([RangeTypes.AnnoInCorso, RangeTypes.AnnoPrecedente]);
}

export function getRangeCorrenti(): DewRange[] {
  return selezionaRange([RangeTypes.SettimanaInCorso, RangeTypes.MeseInCorso, RangeTypes.TrimestreInCorso, RangeTypes.AnnoInCorso]);
}

export function getRangePrecendenti(): DewRange[] {
  return selezionaRange([RangeTypes.MesePrecedente, RangeTypes.TrimestrePrecedente, RangeTypes.AnnoPrecedente]);
}

export function selezionaRange(rangeRichiesti: number[]): DewRange[] {
  return Ranges.filter((item) => rangeRichiesti.indexOf(item.id) !== -1);
}
