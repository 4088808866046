import { Injectable } from '@angular/core';
import { DewStateService } from './dew-state.service';

/**
 * Servizio per la gestione dei permessi dell'utente loggato
 *
 * @export
 * @class DewPermissionService
 */
@Injectable({
  providedIn: 'root',
})
export class DewPermissionService {
  constructor(private stateService: DewStateService) {}

  /**
   * Controlla se l'utente ho determinati permessi.
   *
   * @param {LivelloPermessi} checkPermission
   * @returns {boolean}
   * @memberof DewPermissionService
   */
  isGranted(checkPermission: LivelloPermessi): boolean {
    if (!checkPermission) {
      return false;
    }

    // Controlla di essere loggati
    const profilo = this.stateService.state.profilo;
    if (!profilo) {
      return false;
    }

    // ---- Controlla i permessi
    return checkPermission === profilo.LivelloPermessi;
  }
}
